import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { RemoveFromUserCollections, queryClient } from '../../util/http'; // Assuming you have a function for removing from collections
import { useUser } from '../../store/UserContext';
import ErrorBlock from '../UI/ErrorBlock';
import H2 from '../UI/H2';
import LoadingIndicator from '../UI/LoadingIndicator';
import Modal from '../UI/Modal';

export default function RemoveCollectionRecipeModal({
  recipeId,
  recipeTitle,
  collectionId,
  onClose,
}: {
  recipeId: string;
  recipeTitle: string;
  collectionId: string;
  onClose: () => void;
}) {
  const { user } = useUser();

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: RemoveFromUserCollections,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-collections', user.uid],
      });
      queryClient.invalidateQueries({
        queryKey: ['collections'],
      });
      onClose();
    },
  });

  if (isPending) {
    return <LoadingIndicator />;
  }
  if (isError) {
    return (
      <ErrorBlock
        title='Failed to remove reciope'
        message={
          error?.message ||
          'Failed to remove the recipe from collection. Please try again later.'
        }
      />
    );
  }

  const handleRemove = () => {
    mutate([user.username, collectionId, recipeId]);
  };

  return (
    <Modal>
      <H2 title='Are you sure?' />
      <p>
        Do you want to remove "<strong>{recipeTitle}</strong>" recipe from the
        collection?
      </p>
      <div className='flex justify-end gap-4 mt-8'>
        <button
          onClick={onClose}
          className='py-2 px-4 rounded-lg border border-gray-400'
        >
          Cancel
        </button>
        <button
          onClick={handleRemove}
          className='py-2 px-4 rounded-lg border text-red-100 bg-red-600'
        >
          Remove
        </button>
      </div>
    </Modal>
  );
}
