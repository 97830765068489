export const CATEGORIES = [
  {
    name: 'Desserts',
    value: 'desserts',
  },
  {
    name: 'Fish',
    value: 'fish',
  },
  {
    name: 'Meals',
    value: 'meals',
  },
  {
    name: 'Drinks',
    value: 'drinks',
  },
  {
    name: 'Soups',
    value: 'soups',
  },
  {
    name: 'Vegetarian',
    value: 'vegetarian',
  },
];