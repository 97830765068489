// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyAGkIjVdQjFoGvvPFS7PGRc2iU8Ee3FeAY',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'react-recipes-app-bd56e.firebaseapp.com',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'react-recipes-app-bd56e',
  storageBucket: 'react-recipes-app-bd56e.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '934303504080',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:934303504080:web:62776ee70d979c232370d5',
};

if (process.env.NODE_ENV === 'development') {
  console.log(firebaseConfig, 'firebaseConfig =================')
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();

// Initialize Storage
export const storage = getStorage(app);
