import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { useUser } from '../../store/UserContext';
import {
  fetchUserDashboardNotifications,
  queryClient,
  updateNotificationStatus,
} from '../../util/http';

import Section from '../UI/Section';
import LoadingIndicator from '../UI/LoadingIndicator';
import ErrorBlock from '../UI/ErrorBlock';
import Button from '../UI/Button';
import { StarIcon, UsersIcon } from '@heroicons/react/24/outline';

export default function Notifications() {
  const navigate = useNavigate();
  const { user } = useUser();
  const uid = user?.uid;

  useEffect(() => {
    if (user === null) {
      navigate('/');
    }
  }, [user, navigate]);

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['dashboard', { uid, max: 99 }],
    queryFn: ({ signal, queryKey }) =>
      fetchUserDashboardNotifications({ uid, signal, max: 99 }), // ...queryKey[2] = max: 99
  });

  function handleAcceptInvite(invite: any) {
    updateNotificationStatus({
      uid,
      notificationId: invite,
      action: 'ACCEPT',
    })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['dashboard', { uid, max: 99 }],
        });
        queryClient.invalidateQueries({ queryKey: ['collections'] });
      })
      .catch((error) =>
        console.error('Error updating notification status:', error)
      );
  }

  function handleIgnoreInvite(invite: any) {
    updateNotificationStatus({
      uid,
      notificationId: invite,
      action: 'IGNORE',
    })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: ['dashboard', { uid, max: 99 }],
        })
      )
      .catch((error) =>
        console.error('Error updating notification status:', error)
      );
  }

  function handleAcceptFriend(request: any) {
    updateNotificationStatus({
      uid,
      notificationId: request,
      action: 'ACCEPT',
    })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: ['dashboard', { uid, max: 99 }],
        })
      )
      .catch((error) =>
        console.error('Error updating notification status:', error)
      );
  }

  function handleIgnoreFriend(request: any) {
    updateNotificationStatus({
      uid,
      notificationId: request,
      action: 'IGNORE',
    })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: ['dashboard', { uid, max: 99 }],
        })
      )
      .catch((error) =>
        console.error('Error updating notification status:', error)
      );
  }

  let content: JSX.Element | null = null;
  let contentCollections: JSX.Element | null = null;
  let allNotifications: number = 0;

  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = (
      <ErrorBlock
        title='An error occurred'
        message={error?.message || 'Failed to fetch notifications.'}
      />
    );
  }

  if (data) {
    console.log(data, '------------------------data');
    let collectionsInvites: any[] = [];
    let friendsRequests: any[] = [];

    if (Array.isArray(data)) {
      collectionsInvites = data.filter(
        (notification: any) =>
          notification.type === 'INVITE_TO_COLLECTION' &&
          notification.status === 'PENDING'
      );
      friendsRequests = data.filter(
        (notification: any) =>
          notification.type === 'FRIEND_REQUEST' &&
          notification.status === 'PENDING'
      );

      allNotifications = collectionsInvites.length + friendsRequests.length;
      content = <>{collectionsInvites}</>;
    }

    content = (
      <div className='max-w-3xl m-auto p-12 text-gray-700 bg-white shadow flex flex-col gap-12'>
        {allNotifications === 0 ? (
          <p className='text-center'>There are no new notifications.</p>
        ) : (
          <div>
            {collectionsInvites.length >= 1 ? (
              <>
                <h2 className='flex items-center gap-2 text-sm font-bold mb-8'>
                  <StarIcon className='size-4' />
                  {collectionsInvites.length} invite
                  {collectionsInvites.length > 1 ? 's' : ''} to join a
                  collection
                </h2>
                {collectionsInvites.map((invite) => (
                  <div
                    key={invite.id}
                    className='my-4 pb-4 flex flex-col sm:flex-row items-center gap-4 border-b border-gray-200'
                  >
                    <NavLink
                      to={`/users/${invite.sender?.id}/`}
                      className='shrink-0 rounded-full outline-none focus:ring-4 transition-all'
                    >
                      <img
                        src={invite.sender?.image}
                        alt={invite.sender?.name}
                        className='bg-skeleton rounded-full size-10'
                      />
                    </NavLink>
                    <h3 className='text-pretty'>
                      <NavLink
                        to={`/users/${invite.sender?.id}/`}
                        className='hover:underline outline-none focus:ring-4 transition-all ring-offset-4 rounded-sm'
                      >
                        {invite.sender?.name}
                      </NavLink>{' '}
                      invites you to join the collection: "
                      <strong>
                        <NavLink
                          to={`/collections/${invite.collection.id}/`}
                          title='See the collection'
                          className='hover:underline outline-none focus:ring-4 transition-all ring-offset-4 rounded-sm'
                        >
                          {invite.collection.name}
                        </NavLink>
                      </strong>
                      "
                    </h3>
                    <div className='flex gap-4'>
                      <Button
                        isPrimary
                        onClick={() => handleAcceptInvite(invite.id)}
                      >
                        Accept
                      </Button>
                      <Button onClick={() => handleIgnoreInvite(invite.id)}>
                        Ignore
                      </Button>
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </div>
        )}
      </div>
    );

    contentCollections =
      friendsRequests.length >= 1 ? (
        <div className='max-w-3xl m-auto p-12 text-gray-700 bg-white shadow flex flex-col gap-12'>
          <div>
            <h2 className='flex items-center gap-2 text-sm font-bold mb-8'>
              <UsersIcon className='size-4' />
              {friendsRequests.length} friend request
              {friendsRequests.length > 1 ? 's' : ''}
            </h2>
            {friendsRequests.map((request) => (
              <div
                key={request.id}
                className='my-4 pb-4 flex flex-col sm:flex-row items-center justify-between gap-4 border-b border-gray-200'
              >
                <h3 className='text-pretty flex items-center gap-2'>
                  <NavLink
                    to={`/users/${request.sender?.username}/`}
                    className='flex gap gap-4 items-center'
                  >
                    <img
                      src={request.sender?.image}
                      alt={request.sender?.name}
                      className='bg-skeleton rounded-full size-10'
                    />
                    <strong>{request.sender?.name}</strong>
                  </NavLink>
                  <span>wants to be your friend</span>
                </h3>
                <div className='flex gap-4'>
                  <Button
                    isPrimary
                    onClick={() => handleAcceptFriend(request.id)}
                  >
                    Accept
                  </Button>
                  <Button onClick={() => handleIgnoreFriend(request.id)}>
                    Ignore
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null;
  }

  return (
    <>
      <Section title={`Notifications`} hasIcons={false}>
        {content}
      </Section>
      {contentCollections && (
        <Section hasIcons={false} wrapperClasses='mt-4'>
          {contentCollections}
        </Section>
      )}
    </>
  );
}
