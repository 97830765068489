import { uploadImage as uploadImageApi } from './http';

export async function uploadImage(image, username, recipeId) {
  try {
    const imageUrl = await uploadImageApi(image, username, recipeId);
    return imageUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
}