import React from 'react';
import { useParams } from 'react-router-dom';
import Section from '../UI/Section';
import { useQuery } from '@tanstack/react-query';
import { fetchUserFavorites } from '../../util/http';
import RecipesSkeleton from '../UI/RecipesSkeleton';
import ErrorBlock from '../UI/ErrorBlock';
import RecipeCard from '../Recipes/RecipeCard';
import { useUser } from '../../store/UserContext';
import { Recipe } from '../../types';

export default function UserFavorties() {
  const params = useParams();
  // const [searchParams] = useSearchParams();
  // const searchTerm = searchParams.get('search');

  const { user } = useUser();

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['users', params.username, user?.favorites],
    queryFn: ({ signal }) =>
      fetchUserFavorites({ username: params.username, signal }),
  });

  let content: JSX.Element | null = null;

  if (isPending) {
    content = <RecipesSkeleton />;
  }

  if (isError) {
    content = (
      <ErrorBlock
        title='An error occurred'
        message={error?.message || 'Failed to fetch favorite recipes.'}
      />
    );
  }

  if (data) {
    content = (
      <div className='flex flex-wrap justify-center text-gray-800'>
        {data.map((favorite: Recipe) => {
          return <RecipeCard recipe={favorite} key={favorite.id} />;
        })}
      </div>
    );
  }

  return (
    <>
      <Section title={`Browsing ${params.username}'s favorites`}>
        {content}
      </Section>
    </>
  );
}
