import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useUser } from '../../store/UserContext';
import AddToCollectionsButton from './AddToCollectionsButton';
import H1 from './H1';
import {
  ArrowLeftIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';

interface SectionProps {
  children: React.ReactNode;
  wrapperClasses?: string;
  isNarrow?: boolean;
  title?: string;
  hasRecipes?: boolean;
  hasFavoriteIcon?: boolean;
  hasIcons?: boolean;
  recipe?: { id: string; title: string };
}

export default function Section({
  children,
  wrapperClasses,
  isNarrow,
  title,
  hasRecipes,
  hasFavoriteIcon,
  hasIcons = true,
  recipe,
}: SectionProps) {
  const navigate = useNavigate();
  const { user } = useUser();

  const buttonClasses =
    'w-14 h-14 text-gray-400  rounded-full p-4 shadow hover:shadow-xl hover:bg-white hover:text-gray-700 transition-all';

  return (
    <section
      className={
        isNarrow ? 'max-w-3xl m-auto' : hasRecipes ? 'm-auto' : undefined
      }
    >
      <div className={`${wrapperClasses}`}>
        {title && (
          <div className='ms-2 sm:ms-8 md:ms-4 xl:ms-auto me-2 sm:me-8 md:me-4 xl:me-auto xl:max-w-2xl bg-gray-50 pb-4 xl:pb-0 xl:rounded-full flex gap-4 m-auto justify-between items-center box-content'>
            {hasIcons ? (
              <NavLink
                to='/'
                onClick={() => navigate(-1)}
                className='flex'
                title='Go back'
              >
                <ArrowLeftIcon className={buttonClasses} />
              </NavLink>
            ) : (
              <span />
            )}
            <H1 title={title} />
            {user && hasFavoriteIcon ? (
              recipe && (
                <div className='flex'>
                  <AddToCollectionsButton
                    recipeId={recipe.id}
                    recipeTitle={recipe.title}
                    className={buttonClasses}
                  />
                </div>
              )
            ) : hasIcons ? (
              <NavLink to='/' className='flex' title='Search recipes'>
                <MagnifyingGlassIcon className={buttonClasses} />
              </NavLink>
            ) : (
              <span />
            )}
          </div>
        )}
        {children}
      </div>
    </section>
  );
}
