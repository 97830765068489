import React from 'react';
import RecipeCard from '../Recipes/RecipeCard';

export default function RecipesSkeleton() {
  return (
    <div className='flex flex-wrap justify-center text-gray-800'>
      <RecipeCard skeleton={true} />
    </div>
  );
}
