import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ErrorBlock from '../UI/ErrorBlock';
import { createNewCollection, queryClient } from '../../util/http.js';

import Button from '../UI/Button';
import CollectionForm from './CollectionForm';
import { useUser } from '../../store/UserContext';
import { Collection } from '../../types/collection';

export default function NewCollection() {
  const navigate = useNavigate();
  const { user } = useUser();
  const [collectionId, setCollectionId] = useState(null);

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: createNewCollection,
    onSuccess: (data) => {
      setCollectionId(data.id);
      queryClient.invalidateQueries({ queryKey: ['collections'] });
      queryClient.invalidateQueries({
        queryKey: ['user-collections', user.username],
      });
    },
  });

  function handleSubmit(formData: Collection) {
    const collectionData = {
      uid: user.uid,
      username: user.username,
      collection: formData.name,
      description: formData.description,
    };

    mutate(collectionData);
  }

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Create Collection | Bokkah</title>
        <meta name='description' content='Create a new collection' />
      </Helmet>
      <section className='max-w-3xl m-auto'>
        <h1 className='text-center text-3xl font-bold p-8 text-gray-700'>
          Create Your Collection
        </h1>

        {/* <CollectionForm user={user} onSubmit={handleSubmit}> */}
        <CollectionForm onSubmit={handleSubmit}>
          {isPending && 'Submitting...'}
          {!isPending && (
            <div className='flex gap-4 justify-start'>
              <Button type='submit' isPrimary>
                Create Collection
              </Button>
              <Button onClick={() => navigate(-1)} className='px-4 py-2'>
                Cancel
              </Button>
            </div>
          )}
        </CollectionForm>

        {isError && (
          <ErrorBlock
            title='Failed to create collection'
            message={
              error?.message ||
              'Failed to create collection. Please check your inputs and try again later.'
            }
          />
        )}

        {/* Redirect to the newly created collection */}
        {collectionId && navigate(`/collections/${collectionId}`)}
      </section>
    </>
  );
}
