import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export default function ErrorBlock({
  title,
  message,
}: {
  title: string;
  message?: string;
}) {
  return (
    <div className='fixed top-12 z-50 w-[90%] lg:w-auto max-w-[32rem] px-8 py-6 rounded-md shadow-md bg-red-100 left-1/2 transform -translate-x-1/2'>
      <div className='flex items-center gap-4'>
        <ExclamationTriangleIcon className='w-10 h-10 flex text-red-500 shrink-0' />
        <div>
          <h2 className='font-bold'>{title}</h2>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
}
