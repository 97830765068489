import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  PencilIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';

export default function EditCollectionButton({
  collectionId,
  setIsDeleting,
  setIsSharing,
}: {
  collectionId: string;
  setIsDeleting?: (isDeleting: boolean) => void;
  setIsSharing?: (isSharing: boolean) => void;
}) {
  const menuClasses = 'group flex gap-3 w-full items-center p-4 text-sm';

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <Menu.Button className='inline-flex w-full justify-center rounded-md px-4 py-3 text-sm font-medium text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75'>
        <span>Edit Collection</span>
        <ChevronDownIcon
          className='-mr-1 ml-2 h-5 w-5 text-gray-200 hover:text-gray-100'
          aria-hidden='true'
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute z-[1] right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
          <div className='p-[0.125rem]'>
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to={`/collections/${collectionId}/edit`}
                  className={`${
                    active ? 'bg-gray-700 text-white' : 'text-gray-800'
                  } ${menuClasses} rounded-t-md`}
                >
                  <PencilIcon className='size-5' />
                  Collection details
                </NavLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => setIsSharing && setIsSharing(true)}
                  className={`${
                    active ? 'bg-gray-700 text-white' : 'text-gray-800'
                  } ${menuClasses}`}
                >
                  <UserPlusIcon className='size-5' />
                  Share with others
                </button>
              )}
            </Menu.Item>
          </div>
          <div className='p-[0.125rem]'>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => setIsDeleting && setIsDeleting(true)}
                  className={`${
                    active ? 'bg-red-700 text-white' : 'text-gray-900'
                  } ${menuClasses} rounded-b-md`}
                >
                  <TrashIcon className='size-5' />
                  Delete
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
