import React from 'react';

export default function H2({
  title,
  className,
}: {
  title: string;
  className?: string;
}) {
  return <h2 className={`text-xl font-bold mb-2 ${className}`}>{title}</h2>;
}
