import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchUserFriends } from '../../util/http';
import { useUser } from '../../store/UserContext';
import ErrorBlock from '../UI/ErrorBlock';
import LoadingIndicator from '../UI/LoadingIndicator';
import RecipeCard from '../Recipes/RecipeCard';
import Section from '../UI/Section';
import { User } from '../../types';

export default function UserFriends({
  user,
  onlyRecipes,
}: {
  user?: User;
  onlyRecipes?: Boolean;
}) {
  const params = useParams();
  const username = user ? user.username : params.username;

  const { user: loggedUser } = useUser();

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['friends', username, { max: 99 }],
    queryFn: ({ signal }) => fetchUserFriends({ username, signal, max: 99 }),
  });

  let content;
  let totalFriends;

  if (isPending) {
    content = <LoadingIndicator />;
  }

  if (isError) {
    content = (
      <ErrorBlock
        title='An error occurred'
        message={error?.message || 'Failed to fetch friends.'}
      />
    );
  }
  if (data) {
    totalFriends = data.length - 1;

    content = onlyRecipes ? (
      <div className='flex flex-wrap justify-center'>
        {data.length === 1
          ? 'No friends added yet.'
          : data.map((friend: User) => {
              if (friend.username === username) return null;

              return friend.recipes
                ? friend.recipes.map((recipe) => (
                    // @ts-ignore
                    <RecipeCard recipe={recipe} user={friend} key={recipe.id} />
                  ))
                : null;
            })}
      </div>
    ) : (
      <div className='flex flex-wrap gap-12 justify-center'>
        {data.length === 1
          ? 'No friends added yet.'
          : data.map((friend: User) => {
              if (friend.username === username) return null;

              return (
                <div
                  key={friend.id}
                  className='p-4 px-8 bg-white rounded shadow-md mt-20'
                >
                  <NavLink
                    to={`/users/${friend.username}/`}
                    className='flex flex-col items-center gap-4'
                  >
                    <img
                      src={friend.image}
                      alt={`${friend.name}'s avatar`}
                      className='bg-skeleton rounded-full size-36 relative -top-20 -mb-20 border-white border-4 bg-gray-200'
                    />
                    <h2 className='font-bold my-2'>{friend.name}</h2>
                  </NavLink>
                </div>
              );
            })}
      </div>
    );
  }

  return (
    <div className='md:max-w-[90%] m-auto'>
      <Section
        title={
          loggedUser?.username === username
            ? // @ts-ignore
              totalFriends >= 0
              ? 'Your friends (' + totalFriends + ')'
              : 'No friends added yet.'
            : onlyRecipes
            ? ` friends recipes`
            : // @ts-ignore
            totalFriends >= 0
            ? `${params.username}'s friends (${totalFriends})`
            : `${params.username} has no friends yet.`
        }
      >
        <div className='pt-4'>{content}</div>
      </Section>
    </div>
  );
}
