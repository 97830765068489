import React from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchUserRecipes } from '../../util/http';

import RecipeCard from '../Recipes/RecipeCard';
import RecipesSkeleton from '../UI/RecipesSkeleton';
import Button from '../UI/Button';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { User } from '../../types';

interface UserRecipesProps {
  user: User;
  isSameUser: boolean;
}

export default function UserRecipes({ user, isSameUser }: UserRecipesProps) {
  const { data, isPending }: UseQueryResult<any[]> = useQuery({
    queryKey: ['recipes', { uid: user.id }], // Include uid in the queryKey
    queryFn: ({ signal }) => fetchUserRecipes({ uid: user.id, signal }),
  });

  let content: JSX.Element | null = null;

  if (isPending) {
    content = <RecipesSkeleton />;
  } else if (data) {
    content = (
      <>
        {data.map((recipe) => {
          if (!isSameUser && recipe.draft) {
            return null;
          }
          return <RecipeCard recipe={recipe} key={recipe.id} user={user} />;
        })}
      </>
    );
  }

  return (
    <div className='flex gap-4 flex-col my-16 md:my-24 lg:my-36'>
      {isPending && (
        <h2 className='text-2xl text-center  font-bold text-gray-600'>
          Loading Recipes
        </h2>
      )}
      {!isPending && (
        <h2 className='text-2xl text-center  font-bold text-gray-600'>
          {isSameUser
            ? 'My'
            : user.name.split(' ')[0] + "'s" || user.username + "'s"}{' '}
          Recipes
        </h2>
      )}
      <div className='flex flex-wrap justify-center text-gray-800'>
        {isSameUser && (
          <Button
            isHuge
            to='/recipes/new/'
            className='flex flex-col justify-center items-center text-center border-dashed border-2 border-gray-200 bg-gray-50 hover:bg-white font-bold shadow-lg rounded-lg m-2 sm:m-4 w-[46%] sm:w-[20rem] min-h-40 sm:min-h-[24rem] hover:shadow-xl transition-all'
          >
            <PlusCircleIcon className='w-8 h-8' />
            <span className='pt-4'>Add a new recipe</span>
          </Button>
        )}
        {content}
      </div>
    </div>
  );
}
