import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import ScrollToTop from '../components/UI/ScrollToTop';
import Section from '../components/UI/Section';
import ErrorBlock from '../components/UI/ErrorBlock';

export default function Error({ error }: { error: string }) {
  return (
    <>
      <Header />
      <ScrollToTop />
      <main>
        <Outlet />
        <Section
          isNarrow
          title='Something went wrong!'
          wrapperClasses='text-center'
        >
          <div className='h-12' />
          <ErrorBlock title='An error occured' message={error} />
        </Section>
      </main>
    </>
  );
}
