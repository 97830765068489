import React from 'react';
import { NavLink } from 'react-router-dom';

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  to?: string;
  isPrimary?: boolean;
  isHuge?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  title?: string;
  type?: HTMLButtonElement['type'];
}

export default function Button({
  children,
  className,
  to,
  isPrimary,
  isHuge,
  onClick,
  disabled,
  title,
  type,
}: ButtonProps) {
  const buttonClasses = `border rounded-md ${
    isHuge ? 'py-10 px-8' : 'px-4 py-2'
  } transition-shadow hover:shadow-lg outline-none focus:ring-4 transition-all ${className} ${
    isPrimary
      ? 'bg-gray-700 text-gray-50'
      : 'py-2 px-4 rounded-lg border border-gray-400'
  } `;

  return to ? (
    <NavLink to={to} className={buttonClasses}>
      {children}
    </NavLink>
  ) : (
    <button
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
      title={title}
      // @ts-ignore
      type={type}
    >
      {children}
    </button>
  );
}
