import React from 'react';
import { useUser } from '../../store/UserContext';
// import Section from '../UI/Section';
import UserFriends from '../Users/UserFriends';
import Notifications from './Notifications';

export default function Dashboard() {
  const { user } = useUser();

  return (
    <>
      {/* <Section title={`What's new ${user.name}?`}>test</Section> */}
      <Notifications />
      <div className='p-8'></div>
      {user?.friends && (
        <>
          <UserFriends user={user} />
          <div className='p-8'></div>
          <UserFriends user={user} onlyRecipes={true} />
        </>
      )}
    </>
  );
}
