import { QueryClient } from '@tanstack/react-query';

import { storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Resizer from "react-image-file-resizer";

export const imagesRef = ref(storage, 'images');

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1 * 60 * 60 * 1000, // 1 hour
      gcTime: 3 * 60 * 60 * 1000,   // 3 hours
    },
  },
});

const API_URL = process.env.NODE_ENV === 'production'
  ? 'https://europe-west1-react-recipes-app-bd56e.cloudfunctions.net/api'
  : 'http://localhost:3000';

export async function fetchRecipes({ signal, searchTerm, category, max }) {
  let url = `${API_URL}/recipes`;

  if (searchTerm && max) {
    url += '?search=' + searchTerm + '&max=' + max;
  } else if (searchTerm && !category) {
    url += '?search=' + searchTerm;
  } else if (category && !searchTerm) {
    url += '?category=' + category;
  } else if (searchTerm && category) {
    url += '?category=' + category + '&search=' + searchTerm;
  } else if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, { signal: signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the recipes');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipes } = await response.json();

  return recipes;
}

export async function createNewRecipe(recipeData) {
  const response = await fetch(`${API_URL}/recipes`, {
    method: 'POST',
    body: JSON.stringify(recipeData),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while creating the recipe');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipe } = await response.json();

  return recipe;
}

export async function uploadImage(file, username, recipeId) {
  try {
    const resizedImage = await resizeImage(file);

    let userImagesRef;

    recipeId
      ? userImagesRef = ref(imagesRef, `users/${username}/rcp__${recipeId}__${file.name}`)
      : userImagesRef = ref(imagesRef, `users/${username}/${file.name}`);

    await uploadBytes(userImagesRef, resizedImage);

    const imageUrl = await getDownloadURL(userImagesRef);

    return imageUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
}

// Function to resize the image
const resizeImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1280, // Max width
      1280, // Max height
      'JPEG',
      80, // Quality (0-100)
      0, // Rotation
      (uri) => {
        resolve(uri);
      },
      'blob' // Output type ('blob' or 'base64')
    );
  });

export async function fetchSelectableImages({ signal }) {
  const response = await fetch(`${API_URL}/recipes/images`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the images');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { images } = await response.json();

  return images;
}

export async function fetchRecipe({ id, signal }) {
  const response = await fetch(`${API_URL}/recipes/${id}`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the recipe');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipe } = await response.json();

  return recipe;
}

export async function deleteRecipe({ id }) {
  const response = await fetch(`${API_URL}/recipes/${id}`, {
    method: 'DELETE',
  });

  if (!response.ok) {
    const error = new Error('An error occurred while deleting the recipe');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function updateRecipe({ id, recipe }) {
  const response = await fetch(`${API_URL}/recipes/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ recipe }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while updating the recipe');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function fetchCategories({ signal }) {
  let url = `${API_URL}/categories`;

  const response = await fetch(url, { signal: signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching categories');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { categories } = await response.json();

  return categories;
}

// Users

export async function fetchUsers({ signal, searchTerm, max }) {
  let url = `${API_URL}/users`;

  if (searchTerm && max) {
    url += '?search=' + searchTerm + '&max=' + max;
  } else if (searchTerm) {
    url += '?search=' + searchTerm;
  } else if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, { signal: signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the users');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { users } = await response.json();

  return users;
}

export async function fetchUser({ username, signal }) {
  const response = await fetch(`${API_URL}/users/${username}`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { user } = await response.json();

  return user;
}

export async function fetchUserRecipes({ uid, signal }) {
  const response = await fetch(`${API_URL}/users/get/${uid}/recipes`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user recipes');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipes } = await response.json();

  return recipes;
}

export async function fetchUserByUid({ uid, signal }) {
  if (uid === undefined) return;

  const response = await fetch(`${API_URL}/users/get/${uid}`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user');
    error.code = response.status;
    error.info = await response.json();
    // throw error;
    let user = null;
    return user;
  }

  const { user } = await response.json();

  return user;
}

// export async function AddToUserFavorites(username, recipeId) {
//   try {
//     const response = await fetch(`${API_URL}/users/${username}/favorites`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         recipeId: recipeId,
//       }),
//     });

//     if (response.ok) {
//       alert(`Recipe added to favorites!`);
//       // You can update the UI or perform other actions as needed
//     } else {
//       alert(`Failed to add recipe to favorites. Please try again.`);
//     }
//   } catch (error) {
//     console.error('Error adding recipe to favorites:', error);
//     alert(`An error occurred. Please try again.`);
//   }
// }


export async function fetchUserCollections({ uid, signal }) {
  const response = await fetch(`${API_URL}/users/get/${uid}/collections`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user collections');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  if (response.status === 204) {
    const collections = []

    return collections;
  }

  const { collections } = await response.json();

  return collections;
}

export async function AddToUserCollections([username, collection, recipeId]) {
  try {
    const response = await fetch(`${API_URL}/users/${username}/collections/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        collection: collection,
        recipeId,
      }),
    });

    if (!response.ok) {
      alert(`Failed to add recipe to collection. Please try again.`);
    }
  } catch (error) {
    console.error('Error adding recipe to collection:', error);
    alert(`An error occurred. Please try again.`);
  }
}

export async function RemoveFromUserCollections([username, collectionId, recipeId]) {
  try {
    const response = await fetch(`${API_URL}/users/${username}/collections/${collectionId}/recipes/${recipeId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const error = new Error('Failed to remove recipe from collection. Please try again.');
      error.code = response.status;
      error.info = await response.json();
      throw error;
    }

    return response.json();
  } catch (error) {
    console.error('Error removing recipe from collection:', error);
    alert(`An error occurred. Please try again.`);
  }
}

export async function fetchUserCollectionsByUsername({ username, signal }) {
  const response = await fetch(`${API_URL}/users/${username}/collections/`, {
    signal
  });


  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user collections');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { collections } = await response.json();

  return collections;
}

export async function updateUser({ username, user }) {
  const response = await fetch(`${API_URL}/users/${username}`, {
    method: 'PUT',
    body: JSON.stringify({ user }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while updating the user');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function createNewUser(userData) {
  const response = await fetch(`${API_URL}/users/create`, {
    method: 'POST',
    body: JSON.stringify(userData),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while creating the user');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { user } = await response.json();

  return user;
}

export async function deactivateUser({ username }) {
  const response = await fetch(`${API_URL}/users/${username}?deactivate=true`, {
    method: 'PUT',
  });

  if (!response.ok) {
    const error = new Error('An error occurred while deactivating the user');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function fetchUserFavorites({ username, signal }) {
  const response = await fetch(`${API_URL}/users/${username}/favorites`, {
    signal,
  });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching the user recipes');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { recipes } = await response.json();

  return recipes;
}

export async function fetchUserFriends({ username, signal, max }) {
  let url = `${API_URL}/users/${username}/friends`;

  if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, {
    signal,
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while fetching the ${username} friends`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { friends } = await response.json();

  return friends;
}

export async function fetchNotifications({ uid, signal, max }) {
  let url = `${API_URL}/notifications/${uid}`;

  if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, {
    signal,
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while fetching the ${uid} notifications`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { notifications } = await response.json();

  return notifications;
}

export async function fetchUserDashboardNotifications({ uid, signal, max }) {
  let url = `${API_URL}/dashboard/${uid}/notifications`;

  if (max) {
    url += '?max=' + max;
  }

  const response = await fetch(url, {
    signal,
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while fetching the ${uid} notifications`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { notifications } = await response.json();

  return notifications;
}

export async function updateNotificationStatus({ uid, notificationId, action }) {
  const url = `${API_URL}/dashboard/${uid}/notifications/${notificationId}`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ action }),
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while updating notification status`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const result = await response.json();
  return result;
}

export async function addToFriends({ uid, notifier }) {
  const url = `${API_URL}/notifications/${uid}/add-to-friends`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uid, notifier }),
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while adding to frineds`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const result = await response.json();
  return result;
}

export async function inviteToCollection({ collectionId, uid, invitedUsersIds }) {
  const url = `${API_URL}/notifications/${collectionId}/invite-to-collection`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uid, invitedUsersIds }),
  });

  if (!response.ok) {
    const error = new Error(`An error occurred while inviting frineds to collection`);
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const result = await response.json();
  return result;
}

export async function fetchCollections({ signal }) {
  const url = `${API_URL}/collections`;

  const response = await fetch(url, { signal: signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching collections');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { collections } = await response.json();

  return collections;
}

export async function fetchCollection({ signal, collectionId }) {
  const url = `${API_URL}/collections/${collectionId}`;

  const response = await fetch(url, { signal: signal });

  if (!response.ok) {
    const error = new Error('An error occurred while fetching collection');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { collection } = await response.json();

  return collection;
}

export async function createNewCollection(collectionData) {
  const response = await fetch(`${API_URL}/collections`, {
    method: 'POST',
    body: JSON.stringify(collectionData),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while creating the collection');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const { collection } = await response.json();

  return collection;
}

export async function updateCollection({ id, collection, user, username }) {
  const response = await fetch(`${API_URL}/collections/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ collection, username, updateby: user }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while updating the recipe');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}

export async function deleteCollection({ collectionId, username }) {
  const response = await fetch(`${API_URL}/collections/${collectionId}`, {
    method: 'DELETE',
    body: JSON.stringify({ username }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const error = new Error('An error occurred while deleting the collection');
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  return response.json();
}