import React from 'react';
import { NavLink } from 'react-router-dom';
import CollectionCover from './CollectionCover';
import UsersRoll from '../UI/UsersRoll';
import Button from '../UI/Button';
import { useUser } from '../../store/UserContext';
import { PencilIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { Collection } from '../../types/collection';

export default function CollectionCard({
  collection,
}: {
  collection: Collection;
}) {
  const { user } = useUser();
  return (
    <article
      key={collection.name}
      className='w-full sm:w-[28rem] p-8 flex flex-col gap-4 items-center bg-white shadow-md hover:shadow-xl transition-shadow rounded-lg'
    >
      <NavLink to={`/collections/${collection.id}/`} className='w-full'>
        {collection.recipes?.length > 0 ? (
          <CollectionCover collection={collection} />
        ) : (
          <div className='bg-gray-100 opacity-90 object-cover rounded-md w-full h-[calc(24rem+0.5rem)] flex flex-col gap-4 justify-center items-center'>
            <PhotoIcon className='size-12 text-gray-300' />
            <p className='text-sm text-gray-400'>No recipes</p>
          </div>
        )}
      </NavLink>

      <legend className='w-full flex flex-col h-full'>
        <NavLink to={`/collections/${collection.id}/`} className='w-full'>
          <h2 className='font-bold py-2 text-balance'>{collection.name}</h2>
        </NavLink>
        <p className='pe-2 hyphens-auto text-sm text-gray-600 text-balance text-ellipsis overflow-hidden line-clamp-6 sm:line-clamp-3'>
          {collection.description}
        </p>
        <div className='mt-auto pt-6 flex gap-2 items-center justify-between text-sm text-gray-600 me-3'>
          {user ? (
            <Button to={`/collections/${collection.id}/`}>
              <div className='flex gap-2 items-center'>
                <PencilIcon className='size-4' /> <span>Edit collection</span>
              </div>
            </Button>
          ) : (
            <Button
              to={`/collections/${collection.id}/`}
              className='flex gap-2 items-center'
            >
              Browse collection
            </Button>
          )}
          <UsersRoll users={collection.users} isSmall />
        </div>
      </legend>
    </article>
  );
}
