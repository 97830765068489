import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

interface ModalProps {
  children: React.ReactNode;
  onClose?: () => void;
}

export default function Modal({ children, onClose }: ModalProps) {
  const dialog = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        if (onClose) onClose(); // Call onClose function to close the modal
      }
    };

    dialog.current?.showModal();
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onClose]);

  return createPortal(
    <dialog ref={dialog} className='modal' onClose={onClose}>
      {children}
    </dialog>,
    document.querySelector('#modal') ?? document.body
  );
}
