import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { fetchRecipes } from '../../util/http';
import RecipeCard from './RecipeCard';
import ErrorBlock from '../UI/ErrorBlock';
import RecipesSkeleton from '../UI/RecipesSkeleton';
import Section from '../UI/Section';
import { Recipe } from '../../types';

export default function Recipes() {
  const [searchParams] = useSearchParams();

  const searchTerm = searchParams.get('search');
  const category = searchParams.get('category');

  const { data, isPending, isError, error } = useQuery({
    queryKey: ['recipes', { searchTerm, category, max: 99 }],
    queryFn: ({ signal }) =>
      fetchRecipes({ signal, searchTerm, category, max: 99 }),
  });

  let content;
  let recipesFound = 0;

  if (isPending) {
    content = <RecipesSkeleton />;
  }

  if (isError) {
    content = (
      <ErrorBlock
        title='An error occurred'
        message={error?.message || 'Failed to fetch recipes.'}
      />
    );
  }

  if (data) {
    data.sort((a: any, b: any) => b.createdAt._seconds - a.createdAt._seconds);

    if (data.length > 0) {
      recipesFound = 1;
    }

    content = (
      <div className='flex lg:px-12 flex-wrap justify-center text-gray-800'>
        {data.map((recipe: Recipe) => {
          const user = recipe.user;
          return <RecipeCard user={user} recipe={recipe} key={recipe.id} />;
        })}
      </div>
    );
  }

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>
          {searchTerm ? `Recipes for "${searchTerm}"` : 'Latest Recipes'}
        </title>
        <meta
          property='og:title'
          content={
            searchTerm ? `Recipes for "${searchTerm}"` : 'Latest Recipes'
          }
        />
        <meta
          property='og:description'
          content={`See all the latest recipes added on Bokkah`}
        />
      </Helmet>
      <Section
        title={
          !recipesFound
            ? `No recipes found${searchTerm ? ' for "' + searchTerm + '"' : ''}`
            : searchTerm
            ? `Recipes found for "${searchTerm}"`
            : 'Latest Recipes'
        }
        hasRecipes
      >
        {content}
      </Section>
    </>
  );
}
