import React from 'react';
import LogoAvatar from './LogoAvatar';

export default function AvatarPlaceholder({
  noLogo,
  className,
}: {
  noLogo?: boolean;
  className?: string;
}) {
  return (
    <div
      className={`size-[3rem] rounded-full ${noLogo ? 'bg-skeleton' : ''}`}
      style={{ backgroundColor: `${noLogo ? '' : '#e63329'}` }}
    >
      {!noLogo && <LogoAvatar className='size-full rounded' />}
    </div>
  );
}
