import React, { useState } from 'react';

import { TrashIcon } from '@heroicons/react/24/outline';
import RemoveFromCollectionModal from '../Collections/RemoveCollectionRecipeModal';
import { useParams } from 'react-router-dom';

export default function RemoveFromCollectionsButton({
  className,
  recipeId,
  recipeTitle,
}: {
  className?: string;
  recipeId: string;
  recipeTitle: string;
}) {
  const params = useParams();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className={`p-2 -m-2 flex flex-col items-center justify-center opacity-60 hover:opacity-100 transition-opacity ${className}`}
        title='Remove from collection'
      >
        <TrashIcon className='w-6 h-6' />
        <span className='sr-only'> to collection</span>
      </button>
      {showModal && (
        <RemoveFromCollectionModal
          recipeId={recipeId}
          recipeTitle={recipeTitle}
          collectionId={params.collectionId ?? ''}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}
