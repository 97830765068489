import React from 'react';

export default function ContentBox({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={`p-8 m-4 bg-white shadow-xl rounded-xl ${className}`}>
      {children}
    </div>
  );
}
