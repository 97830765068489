import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import { auth } from '../util/firebase';
import { fetchUserByUid } from '../util/http';

const UserContext = createContext<any>(null);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          // Fetch user data and initialize the user state
          const userData = await fetchUserByUid({
            uid: authUser.uid,
            signal: null,
          });
          const token = await getIdToken(authUser);

          if (userData) {
            setUser({ ...authUser, ...userData, token });
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // Add token refresh logic
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (user) {
        try {
          if (auth.currentUser) {
            const refreshedToken = await getIdToken(auth.currentUser, true); // Force token refresh
            setUser((prevUser: any) => ({
              ...prevUser,
              token: refreshedToken,
            }));
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      }
    }, 30 * 60 * 1000); // 30 minutes

    return () => clearInterval(intervalId);
  }, [user]);

  const signIn = async (authUser: any) => {
    const token = authUser ? await getIdToken(authUser) : null;
    setUser(authUser ? { ...authUser, token } : null);
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <UserContext.Provider value={{ user, signIn, signOut }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
