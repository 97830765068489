export default function Badge({ type }: { type: 'draft' | 'new' }) {
  return (
    <span className='px-3 text-xs bg-gray-200 text-gray-600 p-2 font-bold rounded-full shrink-0'>
      {
        {
          draft: 'Draft',
          new: 'New',
        }[type]
      }
    </span>
  );
}
