import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../store/UserContext';
import {
  Bars2Icon,
  BellIcon,
  BookOpenIcon,
  StarIcon,
  HomeIcon,
  PlusCircleIcon,
  TagIcon,
  UsersIcon,
  XCircleIcon,
  BellAlertIcon,
  BookmarkIcon,
} from '@heroicons/react/24/outline';

import AvatarPlaceholder from './UI/AvatarPlaceholder';
import Button from './UI/Button';

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const { user, signOut } = useUser(); // Destructure signOut directly
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const iconClasses =
    'p-3 flex rounded-full hover:border-gray-300 hover:bg-white hover:shadow-lg transition-all';

  const handleLogout = () => {
    signOut();
    navigate('/');
  };
  // Simulated function to update notification status
  const updateNotifications = () => {
    // Example logic to determine notification status
    const hasNewNotifications = true;
    setHasNotifications(hasNewNotifications);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsUserMenuOpen(false);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClickOutside);
      setIsUserMenuOpen(false);
    };
  }, []);

  useEffect(() => {
    setIsUserMenuOpen(false);
  }, [location.pathname]);

  return (
    <>
      <header className='sticky z-10 top-0 md:pt-2 px-2 sm:px-8 flex items-center justify-between bg-gray-50 xl:bg-transparent text-gray-700 min-h-[5.5rem]'>
        <div className='flex items-center gap-4'>
          <div className='flex gap-4'>
            <button
              onClick={() => setSideMenuIsOpen(true)}
              aria-label='Open Menu'
              className='ps-2 sm:ps-0'
            >
              <Bars2Icon className='h-6 w-6' />
            </button>
            <NavLink to='/' className={user ? 'hidden sm:block' : ''}>
              <h1 className='font-bold text-2xl'>Bokkah</h1>
            </NavLink>
          </div>
          {user && (
            <>
              <div className='flex sm:hidden'>
                <NavLink
                  to='/recipes/new'
                  className={`${iconClasses} border border-gray-300 py-3 px-4`}
                >
                  Add new&nbsp;&nbsp; <PlusCircleIcon className='h-6 w-6' />
                </NavLink>
              </div>
            </>
          )}
        </div>

        <menu
          className={`flex ${user ? 'gap-0 xl:gap-1' : 'gap-8'} items-center`}
        >
          {user && (
            <>
              <li className='hidden sm:block'>
                <NavLink
                  to='/recipes/new'
                  className={`${iconClasses} bg-white border border-gray-300 py-3 ps-4`}
                >
                  New recipe&nbsp;&nbsp; <PlusCircleIcon className='h-6 w-6' />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/users/${user.username}/friends/`}
                  className={iconClasses}
                >
                  <UsersIcon className='h-6 w-6' />
                </NavLink>
              </li>
              <li className={hasNotifications ? 'me-2  rounded-full' : 'me-2'}>
                <NavLink to='/dashboard/notifications/' className={iconClasses}>
                  {hasNotifications ? (
                    <BellAlertIcon className='size-6' />
                  ) : (
                    <BellIcon className='size-6' />
                  )}
                </NavLink>
              </li>
            </>
          )}
          {!user && (
            <>
              <li>
                <Button isPrimary to='/login' className='py-3'>
                  Sign in
                </Button>
              </li>
            </>
          )}
          {user && (
            <li>
              <div className='relative' ref={dropdownRef}>
                <button
                  className='rounded-full overflow-hidden shadow hover:shadow-lg transition-all border-2 border-white'
                  onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                  aria-haspopup='true'
                  aria-expanded={isUserMenuOpen}
                  id='loggedUserAvatar'
                >
                  {user?.image ? (
                    <img
                      src={user?.image}
                      alt={`${user?.name}'s avatar`}
                      className='size-[3.5rem] bg-skeleton'
                    />
                  ) : (
                    <AvatarPlaceholder />
                  )}
                </button>
              </div>
            </li>
          )}
        </menu>
      </header>

      {isUserMenuOpen && (
        <ul className='fixed z-20 right-8 top-24 m-w-40 rounded-lg border border-gray-300 bg-white shadow-lg transition-all overflow-hidden'>
          <li className='py-3 px-5 block w-full border-b mb-2'>
            <span className='font-medium block'>{user.name}</span>
            <span className='text-gray-500'>{user.email}</span>
          </li>
          {/* <li className='mt-2'>
            <NavLink
              to={`dashboard/`}
              className='py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100'
            >
              Dashboard
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to={`/users/${user.username}/`}
              className='py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100'
            >
              Profile
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/users/${user.username}/collections/`}
              className='py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100'
            >
              Collections
            </NavLink>
          </li>
          {/* <li>
              <NavLink
                to={`/users/${user.username}/friends/`}
                className='py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100'
              >
                Friends
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/users/${user.username}/friends/recipes/`}
                className='py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100'
              >
                Friends Recipes
              </NavLink>
            </li> */}
          <li>
            <NavLink
              to={`users/${user.username}/edit/`}
              className='py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100'
            >
              Edit Account
            </NavLink>
          </li>
          <li className='mb-2'>
            <button
              className='py-2 px-5 block w-full text-left font-semibold hover:bg-gray-100'
              onClick={handleLogout}
            >
              Sign out
            </button>
          </li>
        </ul>
      )}

      <div
        className={`w-full h-full fixed  ${
          sideMenuIsOpen
            ? 'backdrop-blur '
            : 'backdrop-blur-0 pointer-events-none'
        } z-10 top-0 left-0 transition-all `}
        onClick={() => setSideMenuIsOpen(false)}
      />
      <aside
        className={`bg-gray-700 text-gray-50 w-full h-full fixed backdrop-blur-sm z-10 top-0 left-0 transition-transform transform max-w-80 shadow-2xl ${
          sideMenuIsOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className='px-4 py-8'>
          <button
            onClick={() => setSideMenuIsOpen(false)}
            className='w-full flex gap-2 py-8 -my-8 pe-12 pl-4 -ml-4 sm:ml-0 sm:-my-7 sm: pb-6'
          >
            <XCircleIcon className='w-6 h-6' />{' '}
            <span className='px-2'>Close menu</span>
          </button>
        </div>
        <menu className='border-t border-t-gray-500 pt-2 border-b border-b-gray-500 pb-2'>
          <li>
            <NavLink
              to={'/'}
              className='px-4 py-8 sm:px-8 text-left font-bold hover:bg-gray-600 flex gap-4'
              onClick={() => setSideMenuIsOpen(false)}
            >
              <HomeIcon className='w-6 h-6' />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/recipes/'}
              className='px-4 py-8 sm:px-8 text-left font-bold hover:bg-gray-600 flex gap-4'
              onClick={() => setSideMenuIsOpen(false)}
            >
              <BookOpenIcon className='w-6 h-6' /> Recipes
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/categories/'}
              className='px-4 py-8 sm:px-8 text-left font-bold hover:bg-gray-600 flex gap-4'
              onClick={() => setSideMenuIsOpen(false)}
            >
              <TagIcon className='w-6 h-6' /> Categories
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/collections/'}
              className='px-4 py-8 sm:px-8 text-left font-bold hover:bg-gray-600 flex gap-4'
              onClick={() => setSideMenuIsOpen(false)}
            >
              <BookmarkIcon className='w-6 h-6' /> Collections
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/users/'}
              className='px-4 py-8 sm:px-8 text-left font-bold hover:bg-gray-600 flex gap-4'
              onClick={() => setSideMenuIsOpen(false)}
            >
              <UsersIcon className='w-6 h-6' /> People
            </NavLink>
          </li>
        </menu>
      </aside>
    </>
  );
}
