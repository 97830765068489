import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { fetchCategories } from '../../util/http';

import Section from '../UI/Section';
import LoadingIndicator from '../UI/LoadingIndicator';
import ErrorBlock from '../UI/ErrorBlock';

interface Category {
  image: string;
  name: string;
  slug: string;
}

export default function Categories() {
  const { data, isPending, isError, error } = useQuery({
    queryKey: ['categories'],
    queryFn: ({ signal }) => fetchCategories({ signal }),
  });

  let content;

  if (isPending) {
    <LoadingIndicator />;
  }

  if (isError) {
    content = (
      <ErrorBlock
        title='An error occurred'
        message={error?.message || 'Failed to fetch categories.'}
      />
    );
  }

  if (data) {
    content = (
      <div className='flex gap-4 justify-center flex-wrap'>
        {data.map((category: Category) => (
          <NavLink
            to={`/categories/${category.slug}/`}
            key={category.name}
            className='p-8 flex flex-col gap-4 items-center bg-white shadow-md hover:shadow-xl transition-shadow'
          >
            <div className='w-[8rem]'>
              <img
                src={category.image}
                alt={category.name}
                className='opacity-90'
                width={120}
                height={120}
              />
            </div>
            {category.name}
          </NavLink>
        ))}
      </div>
    );
  }

  return (
    <>
      {data && (
        <Helmet>
          <title>{`All categories | Bokkah`}</title>
          <meta property='og:title' content={`All categories | Bokkah`} />
          <meta property='og:image' content={`/og-image.jpg`} />
        </Helmet>
      )}
      <Section
        title={'List of Categories'}
        wrapperClasses='lg:max-w-[90%] m-auto'
      >
        <div className='pt-4'>{content}</div>
      </Section>
    </>
  );
}
