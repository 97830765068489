import React from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import Button from './Button';

export default function AddNewCollectionButton() {
  const addNewCollectionButton = (
    <Button
      isHuge
      to='/collections/new/'
      className='flex flex-col justify-center items-center text-center border-dashed border-2 border-gray-200 bg-gray-50 hover:bg-white font-bold shadow-lg rounded-lg 
        w-full sm:w-[28rem] py-8 px-8 min-h-40 sm:min-h-[32rem] hover:shadow-xl transition-all'
    >
      <PlusCircleIcon className='w-8 h-8' />
      <span className='pt-4'>Add a new collection</span>
    </Button>
  );
  return addNewCollectionButton;
}
